import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class AdminService {

    public isAdmin$ = new BehaviorSubject<boolean>(false);

    constructor() {}

    setIsAdmin(value: boolean) {
        this.isAdmin$.next(value);
    }
}