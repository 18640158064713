import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class OrganizationInterceptor implements HttpInterceptor {


    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const toApi = request.url.toLowerCase().startsWith("http://localhost:52323".toLowerCase());

        if (toApi) {
            const cloneRequest = request.clone({
                setHeaders: {
                    Organization: "Metanous_Iot"
                }
            });

            return next.handle(cloneRequest);
        }

        return next.handle(request);
    }

}