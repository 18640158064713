import { Component, OnInit, ViewChild } from '@angular/core';
import { ToHubService } from 'src/app/services/to-hub.service';
import { SignalRService } from 'src/app/services/signal-r.service';
import { Button } from 'primeng/button';
import { MenuItem } from 'primeng/api';
import { NavigationService } from 'src/app/services/navigation.service';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  
  public onoff: boolean = false;
  deviceOffline: boolean = false;

  loading: boolean;
  isAdmin: boolean;

  LINK_GRAFANA: string = 'https://metanous.grafana.net/d/e65742a0-ea68-4ecd-ae0b-d2fedc164eab/iot-demo?orgId=1';

  public liveItem = 'live';
  public controlsItem = 'controls';
  public reportsItem = 'reports';
  public grafanaItem = 'grafana';
  public selectedItem: string = this.liveItem;

  @ViewChild('loadingbutton') loadingButton: Button;

  private subscription = new Subscription();

  constructor(
    private toHubService: ToHubService,
    private signalRService: SignalRService,
    private navigationService: NavigationService,
    public adminService: AdminService,
  ) {}

  ngOnInit(): void {
    this.signalRService
      .getSignalRFeedObservable()
      .subscribe((data: string) => this.handleData(data));

      this.changeSelectedItem(this.liveItem);

    this.subscription.add(
      this.adminService.isAdmin$.subscribe(value => {
        this.isAdmin = value;
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  changeSelectedItem(item: string): void {
    this.selectedItem = item;

    // redirect grafana tab to the chart section
    if (this.selectedItem === this.grafanaItem) {
      this.selectedItem = this.liveItem;

      window.open(this.LINK_GRAFANA, '_blank');
    }

    this.navigationService.setSelectedNavigation(this.selectedItem);
  }

  private handleData(data: string): void {
    try {
      const parsedData = JSON.parse(data);
      if (parsedData.message !== undefined) {
        var message: string = parsedData.message;
        if (message.toLowerCase().includes('shut')) {
          this.onoff = false;
        } else if (message.toLowerCase().includes('start')) {
          this.onoff = true;
        }
      } else if (parsedData.state !== undefined) {
        this.onoff = true;
      }
    } catch (error) {
      console.error('error parsing json', error);
    }
  }

  private send(method: string, payload: string = ''): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.toHubService.send_to_iotHub(method, payload).subscribe({
        next: (result: string) => {
          console.log(result);
          this.deviceOffline = false;
          resolve();
        },
        error: (error: any) => {
          if (error.status === 500) {
            console.log('DEVICE IS OFFLINE');
            this.deviceOffline = true;
          }
          resolve();
        },
      });
    });
  }

  private async sendStop() {
    await this.send('Stop');
  }

  private async sendStart() {
    await this.send('Start');
  }

  async handleToggleButton() {
    this.loading = true;
    if (this.onoff) {
      await this.sendStop();
    } else {
      await this.sendStart();
    }
    if (this.deviceOffline) {
      // address race conditions?
      setTimeout(() => {
        this.onoff = false;
      }, 0);
    }
    this.loading = false;
  }
}
