<div class="page">
  <app-header style="z-index: 1;"></app-header>

  <div class="page__body">
    <router-outlet></router-outlet>
  </div>
</div>

<div class="qr">
  <img src="../assets/images/qr.png"
       alt="QR"
       class="qr__image">
</div>