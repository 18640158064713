import { Component } from '@angular/core';
import { AdminService } from './services/admin.service';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'LegoDemo';
  private adminCode = "759zuzkts368";

  constructor(
    private activatedRoute: ActivatedRoute,
    private adminService: AdminService) {
      
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        let adminParam = params["admin"];

        if(adminParam) {
          if(adminParam == this.adminCode) {
            console.log("admin");
            this.adminService.setIsAdmin(true);
          } else {
            this.adminService.setIsAdmin(false);
          }
        }
      });
  }
}
