import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './modules/shared/shared.module';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { OrganizationInterceptor } from './interceptors/organization.interceptor';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TopBarModule } from './modules/top-bar/top-bar.module';
import { ConfigurationService } from 'src/app/services/config/config.service';

export function initializerFactory(configurationService: ConfigurationService): any {
  // APP_INITIALIZER, except a function return which will return a promise
  // APP_INITIALIZER, angular doesnt starts application untill it completes
  return () => configurationService.loadConfiguration();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    TopBarModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OrganizationInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER, useFactory: initializerFactory,
      deps: [ConfigurationService], multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
