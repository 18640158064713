import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { NgChartsModule } from 'ng2-charts';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { KnobModule } from 'primeng/knob';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    PowerBIEmbedModule,
    NgChartsModule,
    TabMenuModule,
    TableModule,
    KnobModule,
    ToggleButtonModule,
    ButtonModule,
    ReactiveFormsModule,
    InputTextareaModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    PowerBIEmbedModule,
    NgChartsModule,
    TabMenuModule,
    TableModule,
    KnobModule,
    ToggleButtonModule,
    ButtonModule,
    ReactiveFormsModule,
    InputTextareaModule,
  ],
})
export class SharedModule {}
