import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from 'src/app/services/config/config.service';
import { Configuration } from './config/configuration';

@Injectable({
  providedIn: 'root',
})
export class ToHubService {
  private configuration: Configuration;

  reqPayload: { method: string; payload: string } = {
    method: '',
    payload: '',
  };

  constructor(
    public http: HttpClient,
    private configurationService: ConfigurationService
  ) {
    this.configuration = this.configurationService.configuration;
  }

  getUrl(): string {
    return `${this.configuration.apiUri}`;
  }

  send_to_iotHub(method: string, payload: string): Observable<string> {
    this.reqPayload.method = method;
    this.reqPayload.payload = payload;
    const url = `${this.getUrl()}/tohub`;
    return this.http.post<string>(url, this.reqPayload);
  }
}
