import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as signalR from '@microsoft/signalr';
import { ConfigurationService } from 'src/app/services/config/config.service';
import { Configuration } from './config/configuration';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  private signalrConnection: signalR.HubConnection;
  // must be in JSON format
  private signalrFeedSubject = new BehaviorSubject<string>('{}');

  configuration: Configuration;
  constructor(private configurationService: ConfigurationService) {
    this.configuration = this.configurationService.configuration;
  }

  private createSignalRConnection(): signalR.HubConnection {
    return new signalR.HubConnectionBuilder()
      .withAutomaticReconnect()
      .withUrl(this.configuration.signalR_URL, {
        withCredentials: false,
      })
      .configureLogging(signalR.LogLevel.Information)
      .build();
  }

  startSignalRConnection(): void {
    this.setupSignalRConnection();
  }

  stopSignalRConnection(): void {
    if (this.signalrConnection) {
      this.signalrConnection.stop();
      this.signalrConnection = null;
    }
  }

  private setupSignalRConnection(): void {
    if (this.signalrConnection) {
      this.signalrConnection.stop();
      this.signalrConnection = null;
    }

    this.signalrConnection = this.createSignalRConnection();

    this.signalrConnection.on('legomessage', (message: string) => {
      this.signalrFeedSubject.next(message);
    });

    this.signalrConnection.start().catch((err) => console.log(err));
  }

  getSignalRFeedObservable(): Observable<string> {
    return this.signalrFeedSubject.asObservable();
  }
}
